import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, vModelText as _vModelText, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 mb-3" }
const _hoisted_3 = { class: "card mb-5 mb-xl-10" }
const _hoisted_4 = { class: "card-header border-0 cursor-pointer" }
const _hoisted_5 = { class: "card-title m-0" }
const _hoisted_6 = { class: "fw-bolder m-0" }
const _hoisted_7 = { class: "card-body border-top p-9" }
const _hoisted_8 = { class: "row mb-6" }
const _hoisted_9 = { class: "col-lg-4 col-form-label fw-bold fs-6" }
const _hoisted_10 = { class: "col-lg-8" }
const _hoisted_11 = { class: "form-check form-check-custom form-check-solid me-5" }
const _hoisted_12 = ["id", "value"]
const _hoisted_13 = { class: "form-check-label" }
const _hoisted_14 = { class: "row mb-6" }
const _hoisted_15 = { class: "col-lg-4 col-form-label fw-bold fs-6" }
const _hoisted_16 = { class: "col-lg-1" }
const _hoisted_17 = { class: "input-group mb-5" }
const _hoisted_18 = { class: "row mb-6" }
const _hoisted_19 = { class: "col-lg-4 col-form-label fw-bold fs-6" }
const _hoisted_20 = { class: "col-lg-8" }
const _hoisted_21 = { class: "input-group" }
const _hoisted_22 = { class: "input-group mt-5" }
const _hoisted_23 = { class: "card-footer d-flex justify-content-end py-6 px-9" }
const _hoisted_24 = {
  type: "reset",
  class: "btn btn-white btn-active-light-primary me-2"
}
const _hoisted_25 = {
  type: "submit",
  id: "kt_account_profile_details_submit",
  ref: "submitButton",
  class: "btn btn-primary"
}
const _hoisted_26 = { class: "indicator-label" }
const _hoisted_27 = { class: "indicator-progress" }
const _hoisted_28 = { class: "indicator-validate" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.$t("pages.driveManagement.categories.addCat")), 1)
          ])
        ]),
        _createVNode(_component_Form, {
          id: "addDocForm",
          onSubmit: _ctx.addCategory
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t("pages.driveManagement.categories.catTarget")), 1),
                _createElementVNode("div", _hoisted_10, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userTypes, (userType) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "input-group mb-5",
                      key: userType.id
                    }, [
                      _createElementVNode("label", _hoisted_11, [
                        _withDirectives(_createElementVNode("input", {
                          type: "checkbox",
                          id: userType.id,
                          value: userType.id,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.category.user_types) = $event)),
                          class: "form-check-input"
                        }, null, 8, _hoisted_12), [
                          [_vModelCheckbox, this.category.user_types]
                        ]),
                        _createElementVNode("span", _hoisted_13, _toDisplayString(userType.label), 1)
                      ])
                    ]))
                  }), 128))
                ])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("label", _hoisted_15, _toDisplayString(_ctx.$t("pages.driveManagement.categories.catOrder")), 1),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("div", _hoisted_17, [
                    _withDirectives(_createElementVNode("input", {
                      type: "number",
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((this.category.order) = $event)),
                      class: "form-control"
                    }, null, 512), [
                      [_vModelText, this.category.order]
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_18, [
                _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.$t("pages.driveManagement.categories.catLabel")), 1),
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("div", _hoisted_21, [
                    _cache[4] || (_cache[4] = _createElementVNode("span", {
                      class: "input-group-text",
                      id: "basic-addon1"
                    }, "🇫🇷", -1)),
                    _createVNode(_component_Field, {
                      name: "frFileName",
                      type: "text",
                      class: "form-control",
                      placeholder: _ctx.$t('pages.driveManagement.categories.frLabel'),
                      modelValue: this.category.label,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((this.category.label) = $event))
                    }, null, 8, ["placeholder", "modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_22, [
                    _cache[5] || (_cache[5] = _createElementVNode("span", {
                      class: "input-group-text",
                      id: "basic-addon2"
                    }, "🇬🇧", -1)),
                    _createVNode(_component_Field, {
                      name: "enFileName",
                      type: "text",
                      class: "form-control",
                      placeholder: _ctx.$t('pages.driveManagement.categories.enLabel'),
                      modelValue: this.category.label_en,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((this.category.label_en) = $event))
                    }, null, 8, ["placeholder", "modelValue"])
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_23, [
              _createElementVNode("button", _hoisted_24, _toDisplayString(_ctx.$t("general.cancel")), 1),
              _createElementVNode("button", _hoisted_25, [
                _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.$t("general.add")), 1),
                _createElementVNode("span", _hoisted_27, [
                  _createTextVNode(_toDisplayString(_ctx.$t("general.pleaseWait")) + " ", 1),
                  _cache[6] || (_cache[6] = _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle ms-2" }, null, -1))
                ]),
                _createElementVNode("span", _hoisted_28, [
                  _createTextVNode(_toDisplayString(_ctx.$t("general.done")) + " ", 1),
                  _cache[7] || (_cache[7] = _createElementVNode("span", { class: "fas fa-check f-5 ms-3" }, null, -1))
                ])
              ], 512)
            ])
          ]),
          _: 1
        }, 8, ["onSubmit"])
      ])
    ])
  ]))
}